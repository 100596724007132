// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number | "100%",
  backgroundColor?: string,
  color?: string,
  fillOpacity?: number,
  isKnockout?: boolean
}

const XIconWithBackground = (props: PropsType): React.Node => {
  if (props.isKnockout) {
    return (
      <svg

        width={props.size || 32}
        height={props.size || 32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 16C0 7.1648 7.1648 0 16 0C24.8352 0 32 7.1648 32 16C32 24.8352 24.8352 32 16 32C7.1648 32 0 24.8352 0 16ZM22.794 22.8244C23.326 22.3155 23.3363 21.4818 22.8168 20.9607L17.8813 15.9997L22.8168 11.0391C23.3363 10.5179 23.326 9.68407 22.794 9.17519C22.2639 8.66591 21.4112 8.67626 20.8917 9.1975L15.9999 14.1135L11.1081 9.1975C10.5883 8.67626 9.73776 8.66591 9.20578 9.17519C8.67358 9.68407 8.66323 10.5179 9.18301 11.0391L14.1203 15.9997L9.18301 20.9607C8.66323 21.4818 8.67358 22.3155 9.20578 22.8244C9.46639 23.0741 9.80607 23.1996 10.1456 23.1996C10.4954 23.1996 10.845 23.068 11.1081 22.8023L15.9999 17.8861L20.8917 22.8023C21.1564 23.068 21.5042 23.1996 21.8542 23.1996C22.1935 23.1996 22.5332 23.0741 22.794 22.8244Z"
          fill={props.backgroundColor || colors.white}
        />
      </svg>
    )
  }
  return (
    <svg
      width={props.size || 40}
      height={props.size || 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.956 0 0 8.956 0 20C0 31.044 8.956 40 20 40C31.044 40 40 31.044 40 20C40 8.956 31.044 0 20 0"
        fill={props.backgroundColor || colors.midnightBlue70}
        fillOpacity={props.fillOpacity || '0.6'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5212 26.2011C29.1707 26.8524 29.1577 27.8947 28.4927 28.5308C28.1667 28.8429 27.7421 28.9998 27.318 28.9998C26.8805 28.9998 26.4458 28.8352 26.1148 28.5031L20.0001 22.3579L13.8854 28.5031C13.5565 28.8352 13.1195 28.9998 12.6822 28.9998C12.2578 28.9998 11.8332 28.8429 11.5075 28.5308C10.8422 27.8947 10.8293 26.8524 11.479 26.2011L17.6507 19.9999L11.479 13.7991C10.8293 13.1476 10.8422 12.1053 11.5075 11.4692C12.1724 10.8326 13.2356 10.8456 13.8854 11.4971L20.0001 17.6421L26.1148 11.4971C26.7643 10.8456 27.8301 10.8326 28.4927 11.4692C29.1577 12.1053 29.1707 13.1476 28.5212 13.7991L22.3519 19.9999L28.5212 26.2011Z"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default XIconWithBackground

// @flow
import * as React from 'react'
import styled from 'styled-components'
import BackgroundGradient from '@components/BackgroundGradient'
import { CTAPrimaryLink } from '@components/shared'
import { config, Container, Hidden, Visible } from 'react-awesome-styled-grid'
import { colors } from '../shared/colors'

export default function UnlockBanner (): React.Node {
  return (
    <Banner>
      <ImageContainer>
        <BackgroundGradient
          quadrant={3}
          overlayColor='magenta'
        />
      </ImageContainer>

      <Container>
        <Content>
          <TextContainer>
            <Header>Unlock 1000+ spicy stories</Header>
            <SubHeader>Get the full experience with a free trial.</SubHeader>
          </TextContainer>

          <OrangePill href={'/subscribe?promo=try30d&f=2'}>
            <Hidden xs={true} sm={true} md={false}>
              TRY FOR FREE
            </Hidden>
            <Visible xs={true} sm={true} md={false}>
              FREE TRIAL
            </Visible>
          </OrangePill>
        </Content>
      </Container>
    </Banner>
  )
}
const Banner = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
`
const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  gap: .5rem;
`
const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${(props: any): string => config(props).media.md`
    align-items: center;
  `}
`
const SubHeader = styled.p`
  color: ${colors.white};
  font-family: "Good Sans Light";
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%;
  margin: 0;
  padding: 0;
  ${(props: any): string => config(props).media.md`
    font-size: 14px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
  @media only screen and (max-width: 400px) {
    display: none;
  }
`
const Header = styled(SubHeader)`
  display: block;
  font-family: "Good Sans Bold";
  margin-right: .5rem;
  ${(props: any): string => config(props).media.md`
    ::after {
      content: ".";
    }
  `}
`
const OrangePill = styled(CTAPrimaryLink)`
  border-radius: 13px;
  height: 26px;
  line-height: 100%;
  min-width: 100px;
  padding: 0px;
  font-size: 10px;
  z-index: 1;
  ${(props: any): string => config(props).media.md`
    border-radius: 20px;
    height: 40px;
    min-width: 150px;
    font-size: 12px;
  `}
`

// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Container, Hidden, Visible, config } from 'react-awesome-styled-grid'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import BackgroundGradient from '@components/BackgroundGradient'
import { CTAPrimaryLink } from '@components/shared'
import StarRating from '@components/shared/icons/StarRating'
import { colors } from '../shared/colors'
import XIconWithBackground from '../shared/icons/XIconWithBackground'
type PropsType = {
  handleClose: () => void
}

export default function DriveToDownloadBanner ({ handleClose }: PropsType): React.Node {
  const [deviceType, setDeviceType] = React.useState('iOS')
  const [d2dLink, setD2dLink] = React.useState('https://dipsea.app.link/download-app-store')

  const saveToLocalStorage = (): void => {
    try {
      handleClose()
      window.localStorage.setItem(`dipsea_download_banner`, Date.now())
    } catch (e) {
      console.error('Failed to set to local storage: dipsea_blog', e)
    }
  }

  React.useEffect((): void => {
    if (isAndroid && isMobile) {
      setD2dLink('https://dipsea.app.link/download-play-store')
      setDeviceType('Android')
    } else if (isIOS && isMobile) {
      setDeviceType('iOS')
    }
  }, [])

  return (
    <Banner>
      <ImageContainer>
        <BackgroundGradient
          quadrant={1}
          overlayColor='magenta'
        />
      </ImageContainer>

      <Container>
        <Content>
          <Left>
            <CloseButton aria-label="Close" onClick={saveToLocalStorage}>
              <XIconWithBackground
                size={'100%'}
                backgroundColor={'transparent'}
              />
            </CloseButton>

            <DipseaIconImg src="/images/dipsea-logo.png" alt="Dipsea icon" />

            <ContentWrapper>
              <TextContainer>
                <Header>Dipsea for {deviceType}</Header>
                <SubHeader>
                  It’s even better on the app.
                </SubHeader>
              </TextContainer>

              <StarContainer>
                <StarWrapper>
                  <StarRating color={colors.white} />
                </StarWrapper>
                <ReviewCountText>6.2k reviews</ReviewCountText>
              </StarContainer>
            </ContentWrapper>
          </Left>

          <OrangePill
            target={'_blank'}
            href={d2dLink}
            rel="noopener noreferrer"
          >
            <Hidden xs={true} sm={true} md={false}>
              Download for free
            </Hidden>
            <Visible xs={true} sm={true} md={false}>
              Download
            </Visible>
          </OrangePill>
        </Content>
      </Container>
    </Banner>
  )
}

const OrangePill = styled(CTAPrimaryLink)`
  border-radius: 13px;
  height: 26px;
  line-height: 100%;
  min-width: 100px;
  padding: 0px;
  font-size: 10px;
    z-index: 1;
  ${(props: any): string => config(props).media.md`
    border-radius: 20px;
    height: 40px;
    padding: 0 12px;
    min-width: 126px;
    font-size: 12px;
  `}
`

const Banner = styled.div`
 display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  z-index: 1;
`
const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  gap: .5rem;
`
const Left = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  gap: .5rem;
`
const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${(props: any): string => config(props).media.md`
    align-items: center;
  `}
`
const StarContainer = styled.div`
  display: none;
  @media only screen and (min-width: 430px) {
    display: flex;
    align-items: center;
    gap: .25rem;
  }
`
const StarWrapper = styled.div`
  height: 12px;
  display: flex;
  ${(props: any): string => config(props).media.md`
    height: 14px;
  `}
  ${(props: any): string => config(props).media.lg`
    height: 16px;
  `}
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DipseaIconImg = styled.img`
  width: 28px;
  height: 28px;
  ${(props: any): string => config(props).media.md`
    width: 42px;
    height: 42px;
  `}
`
const SubHeader = styled.p`
  color: ${colors.white};
  font-family: "Good Sans Light";
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%;
  margin: 0;
  padding: 0;
  ${(props: any): string => config(props).media.md`
    font-size: 14px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
  @media only screen and (max-width: 542px) {
    display: none;
  }
`
const Header = styled(SubHeader)`
  display: block;
  font-family: "Good Sans Bold";
  margin-right: .5rem;
  ${(props: any): string => config(props).media.md`
    ::after {
      content: ".";
    }
  `}
`
const ReviewCountText = styled(SubHeader)`
  line-height: 100%;
  display: block;
  font-size: 9px;
  ${(props: any): string => config(props).media.md`
    font-size: 10px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 12px;
  `}
`
const CloseButton = styled.button`
  border: none;
  border-radius: 20px;
  cursor: pointer;
  height: 23px;
  margin: 0 0 0 -6px;
  min-height: 23px;
  min-width: 23px;
  outline: none;
  padding: 0;
  background: none;
  width: 23px;
  z-index: 1;
  :focus {
    box-shadow: 0 0 0 2pt ${colors.lightPurple};
  } 
  ${(props: any): string => config(props).media.md`
    height: 32px;
    width: 32px;
    margin: 0 0 0 -8px;
  `};
  ${(props: any): string => config(props).media.lg`
    height: 40px;
    width: 40px;
    margin: 0 0 0 -10px;
  `};
`

// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import HtmlHead from '../HtmlHead'
import type { AppStateType } from '../../reducers/appstate'
import { colors } from '@components/shared/colors'
import TopNav from '@components/TopNav'
import Footer from '@components/Footer'
import { GlobalStyle } from '@components/shared'
import TopNavMobile from '@components/TopNavMobile'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { DiscoverBottomMobileNav } from '@components/shared/DiscoverMobileBottomNav'
import AgeGateModal from '@components/Discover/AgeGateModal'
import styled from 'styled-components'
import { LockedModal } from '@components/Discover/LockedModal'
import BackgroundGradient from '@components/BackgroundGradient'
import Banner from '@components/Banner'

type PropsType = {
  children: React.Node,
  title: string,
  description?: string,
  imageUrl: string,
  background?: boolean,
  mobileBackgroundXS?: boolean,
  canonicalUrl?: string
}

type DispatchPropsType = {}

const toggleIntercom = (hide: boolean): void => {
  if (typeof window === 'undefined') return
  if (typeof window.Intercom === 'undefined') return
  window.Intercom('update', {
    hide_default_launcher: hide
  })
}
function DiscoverLayout (props: PropsType & DispatchPropsType): React.Node {
  React.useEffect((): any => {
    toggleIntercom(true)
    return (): void => {
      toggleIntercom(false)
    }
  }, [])
  return (
    <>
      <GlobalStyle backgroundColor={colors.midnightBlue} />
      <HtmlHead title={props.title || 'Dipsea | Spicy Audiobooks'}
        description={props.description ? props.description.slice(0, 155) : 'Spicy audiobooks and more, featuring your favorite tropes and irresistible characters. Crafted by our team of professional writers and top-tier narrators.'}
        imageUrl={props.imageUrl}
        canonicalUrl={props.canonicalUrl}
        includeStripe={true}
      />

      <TopNav backgroundColor={colors.midnightBlue95} isAssetLoaded={true} isDiscover={true} isStatic={true} />
      <TopNavMobile backgroundColor={colors.midnightBlue95} isAssetLoaded={true} isDiscover={true} />
      <Banner />
      {props.background && <BackgroundImageContainer>
        <BackgroundGradient
          quadrant={3}
          gradientColor={colors.midnightBlue}
          gradientPosition='bottom'
          overlayColor='blue'
        />
      </BackgroundImageContainer>}
      <Container>
        <MinHeight>
          <Col>
            <main>
              {props.children}
            </main>
          </Col>
        </MinHeight>
      </Container>
      <AgeGateModal />
      <LockedModal />
      <Footer backgroundColor={colors.midnightBlue} />
      <DiscoverBottomMobileNav />
    </>
  )
}

const MinHeight = styled(Row)`
  min-height: calc(100vh - 160px);
`
const mapStateToProps = (state: AppStateType, ownProps: PropsType): any => {
  return ownProps
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverLayout)

const BackgroundImageContainer = styled.div`
  height: 70%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`

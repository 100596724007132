// @flow
import * as React from 'react'

export type PropsType = {
  size?: number,
  color?: string
}

export default function StarRating (props: PropsType): React.Node {
  return (
    <svg width={props.size || '100%'} height={props.size || '100%'} viewBox="0 0 58 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.27447 1.46353C5.42415 1.00287 6.07585 1.00287 6.22553 1.46353L6.9287 3.62766C6.99564 3.83367 7.18761 3.97315 7.40423 3.97315H9.67973C10.1641 3.97315 10.3655 4.59296 9.97363 4.87766L8.1327 6.21517C7.95746 6.34249 7.88413 6.56818 7.95107 6.77419L8.65424 8.93832C8.80391 9.39898 8.27667 9.78204 7.88482 9.49734L6.04389 8.15983C5.86865 8.03251 5.63135 8.03251 5.45611 8.15983L3.61518 9.49734C3.22333 9.78204 2.69609 9.39898 2.84576 8.93832L3.54893 6.77419C3.61587 6.56818 3.54254 6.34249 3.3673 6.21517L1.52637 4.87766C1.13452 4.59296 1.3359 3.97315 1.82027 3.97315H4.09577C4.31239 3.97315 4.50436 3.83367 4.5713 3.62766L5.27447 1.46353Z" fill={props.color}/>
      <path d="M16.7745 1.46353C16.9241 1.00287 17.5759 1.00287 17.7255 1.46353L18.4287 3.62766C18.4956 3.83367 18.6876 3.97315 18.9042 3.97315H21.1797C21.6641 3.97315 21.8655 4.59296 21.4736 4.87766L19.6327 6.21517C19.4575 6.34249 19.3841 6.56818 19.4511 6.77419L20.1542 8.93832C20.3039 9.39898 19.7767 9.78204 19.3848 9.49734L17.5439 8.15983C17.3686 8.03251 17.1314 8.03251 16.9561 8.15983L15.1152 9.49734C14.7233 9.78204 14.1961 9.39898 14.3458 8.93832L15.0489 6.77419C15.1159 6.56818 15.0425 6.34249 14.8673 6.21517L13.0264 4.87766C12.6345 4.59296 12.8359 3.97315 13.3203 3.97315H15.5958C15.8124 3.97315 16.0044 3.83367 16.0713 3.62766L16.7745 1.46353Z" fill={props.color}/>
      <path d="M28.2745 1.46353C28.4241 1.00287 29.0759 1.00287 29.2255 1.46353L29.9287 3.62766C29.9956 3.83367 30.1876 3.97315 30.4042 3.97315H32.6797C33.1641 3.97315 33.3655 4.59296 32.9736 4.87766L31.1327 6.21517C30.9575 6.34249 30.8841 6.56818 30.9511 6.77419L31.6542 8.93832C31.8039 9.39898 31.2767 9.78204 30.8848 9.49734L29.0439 8.15983C28.8686 8.03251 28.6314 8.03251 28.4561 8.15983L26.6152 9.49734C26.2233 9.78204 25.6961 9.39898 25.8458 8.93832L26.5489 6.77419C26.6159 6.56818 26.5425 6.34249 26.3673 6.21517L24.5264 4.87766C24.1345 4.59296 24.3359 3.97315 24.8203 3.97315H27.0958C27.3124 3.97315 27.5044 3.83367 27.5713 3.62766L28.2745 1.46353Z" fill={props.color}/>
      <path d="M39.7745 1.46353C39.9241 1.00287 40.5759 1.00287 40.7255 1.46353L41.4287 3.62766C41.4956 3.83367 41.6876 3.97315 41.9042 3.97315H44.1797C44.6641 3.97315 44.8655 4.59296 44.4736 4.87766L42.6327 6.21517C42.4575 6.34249 42.3841 6.56818 42.4511 6.77419L43.1542 8.93832C43.3039 9.39898 42.7767 9.78204 42.3848 9.49734L40.5439 8.15983C40.3686 8.03251 40.1314 8.03251 39.9561 8.15983L38.1152 9.49734C37.7233 9.78204 37.1961 9.39898 37.3458 8.93832L38.0489 6.77419C38.1159 6.56818 38.0425 6.34249 37.8673 6.21517L36.0264 4.87766C35.6345 4.59296 35.8359 3.97315 36.3203 3.97315H38.5958C38.8124 3.97315 39.0044 3.83367 39.0713 3.62766L39.7745 1.46353Z" fill={props.color}/>
      <path d="M51.2726 1.53314C51.4185 1.06468 52.0815 1.06468 52.2274 1.53314L52.9316 3.79455C52.9966 4.00353 53.1901 4.1459 53.4089 4.1459H55.7314C56.2106 4.1459 56.4154 4.75484 56.0335 5.04434L54.1185 6.49619C53.9506 6.62342 53.8805 6.84219 53.9431 7.04328L54.6659 9.36441C54.8105 9.82883 54.2741 10.2054 53.8864 9.9115L52.0521 8.5208C51.8735 8.38539 51.6265 8.38539 51.4479 8.5208L49.6136 9.9115C49.2259 10.2054 48.6895 9.82883 48.8341 9.36441L49.5569 7.04328C49.6195 6.84219 49.5494 6.62342 49.3815 6.49619L47.4665 5.04434C47.0846 4.75484 47.2894 4.1459 47.7686 4.1459H50.0911C50.3099 4.1459 50.5034 4.00353 50.5684 3.79455L51.2726 1.53314Z" fill="white" fillOpacity="0.25"/>
      <path d="M49.6136 9.9115L51.5521 8.44185C51.6768 8.34733 51.75 8.19988 51.75 8.04341V1.31502C51.75 1.08584 51.4272 1.03674 51.3591 1.25556L50.5685 3.79455C50.5034 4.00353 50.31 4.1459 50.0911 4.1459H47.7686C47.2894 4.1459 47.0847 4.75484 47.4665 5.04434L49.3816 6.49619C49.5494 6.62342 49.6195 6.84219 49.5569 7.04328L48.8341 9.36441C48.6895 9.82883 49.226 10.2054 49.6136 9.9115Z" fill={props.color}/>
    </svg>
  )
}

// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'
import { useDispatch, useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'
import Modal from '@components/shared/FullScreenModal'
import { toggleSubscriptionModalAction } from '../../actions/subscriptionActions'
import { H1 } from '@components/shared'
import SubscribeCta from '@components/SubscribeCta'

const LockedIcon = (): React.Node => {
  return (<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="21.4667" stroke="white" strokeWidth="3.06667"/>
    <g mask="url(#mask0_711_15163)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20.0499 18.9756C20.0499 17.2176 21.4996 15.6189 23.0896 15.6189C24.6801 15.6189 26.1303 17.2176 26.1303 18.9756V22.6258H20.0499V18.9756ZM29.3962 22.6259H28.7501V18.9758C28.7501 15.8087 26.1026 13.0337 23.0886 13.0337C20.075 13.0337 17.428 15.8087 17.428 18.9758V22.6259H16.7815C16.1787 22.6259 15.6895 23.1081 15.6895 23.6984V31.8902C15.6895 32.4844 16.1787 32.9675 16.7815 32.9675H29.3962C29.999 32.9675 30.4882 32.4844 30.4882 31.8902V23.6984C30.4882 23.1081 29.999 22.6259 29.3962 22.6259V22.6259Z"
            fill="white"/>
    </g>
  </svg>)
}

export function LockedModal (): React.Node {
  const isVisible = useSelector((state: AppStateType): boolean => state.lockedModalVisible)
  const dispatch = useDispatch()
  return (
    <Modal
      isVisible={isVisible}
      disableFullScreenMobile
      overflow="unset"
      toggleModalFunction={(): void => dispatch(toggleSubscriptionModalAction())}
      closeButtonHeight={60}
      bgGradient={false}
    >
      <Card>
        <TextContainer>
          <LockedIcon/>
          <Header>30 days for free</Header>
          <DescriptionContainer>Unlock 1,000+ original spicy audiobooks and more, featuring your favorite tropes and irresistible new characters.</DescriptionContainer>
          <SubscribeCta />
        </TextContainer>
      </Card>
    </Modal>
  )
}

const DescriptionContainer = styled.div`
    font-family: "Good Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: -0.3px;
    color: ${colors.white};
    padding-bottom: 42px;
    text-align: center;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
    font-size: 18px;
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const Header = styled(H1)`
  color: ${colors.white};
  font-family: "Queens Condensed Light";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-top: 23px;
  margin-bottom: 20px;
  text-align: center;
  ${(props: any): string => config(props).media.md`
    font-size: 36px;
    margin-top: 30px;
  `}

  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
  `}
`

const Card = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  position: relative;
  ${(props: any): string => config(props).media.sm`
    max-width: 400px;
  `}
  ${(props: any): string => config(props).media.lg`
    max-width: 472px;
  `}
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

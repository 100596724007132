// @flow
import * as React from 'react'
import { config } from 'react-awesome-styled-grid'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import DriveToDownloadBanner from '@components/Discover/DriveToDownloadBanner'
import UnlockBanner from '@components/Discover/UnlockBanner'
import type { AppStateType } from '@reducers/appstate'
import { useSearchParams } from 'next/navigation'

export default function Banner (): React.Node {
  const userId = useSelector((state: AppStateType): string => state.user?.id)
  const userLoaded = useSelector((state: AppStateType): boolean => state.userLoaded)
  const isSubscribed = useSelector((state: AppStateType): boolean => !!state.user?.isSubscribed)
  const [bannerType, setBannerType] = React.useState(null)
  const searchParams = useSearchParams()
  const [hideBannerGap, setHideBannerGap] = React.useState(false)

  const isDownloadPopAvaliable = (): boolean => {
    try {
      if (window?.localStorage?.dipsea_download_banner && window?.localStorage?.dipsea_download_banner > Date.now() - 1000 * 60 * 60 * 24 * 7) {
        return false
      }
      const userAgent = (window?.navigator || {}).userAgent
      if (['Bingbot', 'Googlebot'].includes(userAgent)) {
        // don't show modal to google bot
        return false
      }
    } catch (e) {
      console.error('Failed to fetch local storage: dipsea_blog', e)
    }
    return !!isSubscribed && !!userId
  }

  React.useEffect((): void => {
    const susbcribeActive = searchParams.get('subscribe') === 't'
    const dontShow = searchParams.get('banner') === 'f'
    if (dontShow) {
      setHideBannerGap(true)
    } else if (userLoaded && isDownloadPopAvaliable()) {
      setBannerType('download')
    } else if (susbcribeActive) {
      setHideBannerGap(true)
    } else if (userLoaded && !isSubscribed && !susbcribeActive) {
      setBannerType('unlock')
    } else if (!userLoaded && !userId && !susbcribeActive) {
      setBannerType('unlock')
    } else {
      setBannerType(null)
    }
  }, [userLoaded, userId, isSubscribed, searchParams])

  if (hideBannerGap) {
    return null
  }

  return (
    <Container>
      {bannerType === 'download' && <DriveToDownloadBanner handleClose={(): void => setBannerType('')} />}
      {bannerType === 'unlock' && <UnlockBanner />}
    </Container>
  )
}

const Container = styled.div`
  height: 40px;
  overflow: hidden;
  position: relative;
  ${(props: any): string => config(props).media.md`
    height: 54px;
  `}
  ${(props: any): string => config(props).media.lg`
    height: 68px;
  `}
`

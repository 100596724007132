// @flow
import * as React from 'react'
import {
  colors,
  CTASecondary
} from '@components/shared'
import styled from 'styled-components'
import Modal from '@components/shared/Modal'
import { H1 } from '@components/shared/typography'
import { useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'
import { updateAgeAsync } from '../../models/User'
import { useRouter } from 'next/router'
import BackgroundGradient from '@components/BackgroundGradient'
import { useSearchParams } from 'next/navigation'
const DELAY_POP_TIME = 5000 // 5 seconds

export default function AgeGateModal (): React.Node {
  const ageVerified = useSelector((state: AppStateType): boolean => !!state?.user?.ageVerified)
  const [isVisible, setIsVisible] = React.useState(false)
  const router = useRouter()
  const searchParams = useSearchParams()
  const saveToLocalStorage = (): void => {
    try {
      window.localStorage.setItem(`dipsea_age_status`, 1)
      window.localStorage.setItem(`dipsea_age_date`, Date.now())
      if (!ageVerified) {
        updateAgeAsync()
      }
      setIsVisible(false)
    } catch (e) {
      console.error('Failed to set to local storage: dipsea_blog', e)
    }
  }

  const isPopAvaliable = (): boolean => {
    try {
      if (searchParams.get('a')) {
        return false
      }
      if (window?.localStorage?.dipsea_age_status) {
        if (window.localStorage?.dipsea_age_status) {
          // email already saved, don't show modal
          return false
        }
      }
      const userAgent = (window?.navigator || {}).userAgent
      if (['Bingbot', 'Googlebot'].includes(userAgent)) {
        // don't show modal to google bot
        return false
      }
    } catch (e) {
      console.error('Failed to fetch local storage: dipsea_blog', e)
    }

    // can show modal
    return true
  }

  React.useEffect((): any => {
    let timeout
    if (isPopAvaliable() && !ageVerified) {
      timeout = setTimeout((): void => setIsVisible(true), DELAY_POP_TIME)
    } else if (isPopAvaliable() && ageVerified) {
      saveToLocalStorage()
    }
    return (): void => {
      timeout && clearTimeout(timeout)
    }
  }, [searchParams, ageVerified])

  const handleSave = (e: any): void => {
    e.preventDefault()
    saveToLocalStorage()
  }

  const handleAmNot = (): void => {
    router.push('/')
  }
  if (ageVerified) return null
  return (
    <PageContainer>
      <Modal
        isVisible={isVisible}
        modalCardBackgroundColor={colors.violet}
        disableFullScreenMobile
        overflow='unset'
      >
        <BackgroundGradient
          quadrant={3}
          overlayColor='magenta'
        />
        <Card>
          <TextContainer>
            <Header>Are you 18 or older?</Header>
            <SubmitButton hoverOpacity={true} onClick={handleSave}>
              I AM
            </SubmitButton>
            <SkipButton hoverOpacity={true} onClick={handleAmNot}>I AM NOT</SkipButton>
          </TextContainer>
        </Card>
      </Modal>
    </PageContainer>
  )
}

const SubmitButton = styled(CTASecondary)`
  margin: 31px 0 22px;
  min-height: 40px;
  padding: 10px;
  width: 80%;
  height: unset;
  
  &:focus {
      outline: none;
      box-shadow: none;
      transition: none;
      border: 1px solid ${colors.white};
      color: ${colors.white};
      opacity: 1;
  }

`
const SkipButton = styled(CTASecondary)`
  align-self: center;
  font-family: 'Good Sans Regular';
  width: 80%;
  font-weight: 500;
  margin: 0 0 30px;
`

const PageContainer = styled.div`
  .modal-window {
    border-radius: 20px;
    max-width: 414px;
  }
`

const Header = styled(H1)`
  color: ${colors.white};
  font-family: "Queens Condensed Light";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
`

const Card = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 56px 20px 38px;
  width: 100%;
  position: relative;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
